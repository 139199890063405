import React, { useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

const MainSection = () => {
  const [forData, setForData] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState(null);

  const handleClickRegister = () => {
    console.log("register");
  };

  return (
    <section id="hero" className=" ">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 col-md-12 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img"
            data-aos="fade-up"
          >
            <Box
              sx={{
                bgcolor: "#fff",
                width: "70%",
                height: "100%",
                paddingX: 2,
                paddingY: 6,
                borderRadius: 4,
                display: "grid",
              }}
              gap={2}
            >
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">For</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="For"
                  sx={{
                    bgcolor: "#fff",
                  }}
                  value={forData}
                  onChange={(e) => console.log(e)}
                >
                  <MenuItem value={1}>Myself</MenuItem>
                  <MenuItem value={2}>Daughter</MenuItem>
                  <MenuItem value={3}>Son</MenuItem>
                  <MenuItem value={4}>Sister</MenuItem>
                  <MenuItem value={5}>Brother</MenuItem>
                  <MenuItem value={6}>Relative</MenuItem>
                  <MenuItem value={7}>Friend</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{
                  bgcolor: "#fff",
                }}
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                size="small"
              />

              <TextField
                sx={{
                  bgcolor: "#fff",
                }}
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                size="small"
              />
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Gender"
                  sx={{
                    bgcolor: "#fff",
                  }}
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                </Select>
              </FormControl>
              <Button
                className="button"
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => handleClickRegister()}
              >
                Register Free
                <ArrowRightAltIcon />
              </Button>
            </Box>

            {/* <img
              style={{ width: "140%", height: "600px" }}
              src={require("../../../assets/img/img1.gif")}
              className="img-fluid"
              alt=""
            /> */}
          </div>
          {/* <div
              className="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1 "
              data-aos="fade-up"
            >
              <div>
                <h1>Welcome to Pellifix</h1>
                <h2 className="content-home">
                  Life is the greatest gift humanity has been given, and
                  surviving it is the ultimate test. Having a great partner by
                  your side can make this journey much easier and more
                  enjoyable. Here at Pelli fix, we take great pleasure in
                  helping you find the perfect partner to make your life more
                  glorious.
                </h2>
                <span
                onClick={() => navigate("/login")}
                className="download-btn"
              >
                Login
              </span>
              <span
                onClick={() => navigate("/register")}
                className="download-btn"
              >
                Register
              </span>
              </div>
            </div> */}
        </div>
        <Typography variant="h2">
          Life is the greatest gift humanity has been given, and surviving it is
          the ultimate test. Having a great partner by your side can make this
          journey much easier and more enjoyable. Here at Pelli fix, we take
          great pleasure in helping you find the perfect partner to make your
          life more glorious.
        </Typography>
      </div>
    </section>
  );
};
export default MainSection;
